import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AI_gif1 from "../../assets/Recruitment/AI_1.gif";
import AI_3 from "../../assets/Recruitment/AI_3.png";
import AI_gif5 from "../../assets/Recruitment/A1_5.gif";
import AI_4 from "../../assets/Recruitment/AI_4.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


export default function SimpleAccordion() {
  return (
    <div
      className="challenges"
      style={{ backgroundColor: "#071013", color: "white" }}
    >
      <div className="challenge-intro">
        <h1 className="black" style={{ color: "#f49221" }}>
          Recruitment <span className="orange">Challenges</span>
        </h1>
        <p className="black" style={{ color: "#ffffff" }}>
          Catch us at the Open House on <b>28th August 2024. </b>
          <br />
        </p>
        <p className="black" style={{ color: "#ffffff" }}>
          Project MANAS brings you the opportunity to fast-track into the
          TaskPhase by competing in the Recruitment Challenges.
          <br />
          <b>
            MIT Students only from first year are eligible to apply for the same
          </b>
          .
          <br />
          <br />
          Eligible students can attempt the questions belonging to any division
          without requiring any pre-requisites.
          <br />
          <br />
          You are <b>recommended to apply for the interviews</b>, over{" "}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeNcbiQ5Od7yBgPGxPezvKWrOgYWL69-whGDMGfe9Zb8F99mQ/viewform"
          >
            here
          </a>
          , regardless of your submission of the Recruitment Challenge.
        </p>
        <p className="black" style={{ color: "#ffffff" }}>
          The deadline for the Recruitment Challenges of all divisions is{" "}
          <h3 style={{ color: "white" }}>
            <b>11th September 2024 at 11:59 PM.</b>
          </h3>
        </p>
        <p className="black" style={{ color: "#ffffff" }}>
          <h3 style={{ color: "white" }}>
            <b>Partial submissions are also encouraged.</b>
          </h3>
        </p>
        <p className="black" style={{ color: "#ffffff" }}>
          Recruitment Challenges is one of the ways to get into the Taskphase. Alternatively, you can choose to apply directly for the interviews.
          
        </p>
      </div>
      <div>
        <Accordion className="Accordian" style={{ backgroundColor: "white" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <h3>Artificial Intelligence</h3>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div
                id="ai-challenge"
                style={{ backgroundColor: "white", color: "black" }}
              >
                <div className="challenge-content">
                  <object align="left">
                    <h1 className="orange" style={{ color: "#f49221" }}>
                      Artificial{" "}
                      <span className="white">&nbsp;Intelligence</span>
                    </h1>
                    <p>
                      The challenges for AI recruitments have been hosted on
                      HackerRank. The challenges includes easy and hard
                      challenges.
                    </p>
                    <p>
                      The challenge is live on{" "}
                      <a
                        target="_blank"
                        href="https://www.hackerrank.com/project-manas-ai-recruitment-challenge-2024-2025"
                        rel="noreferrer noopener"
                      >
                        HackerRank{" "}
                      </a>
                    </p>
                    <h2 className="">
                      <b>Long Lost Brothers</b>
                    </h2>
                    <p>
                      Dino Tim wants to see his Triassic friends, so he has
                      asked his trusted friend David Jijo to help him out. So,
                      David, being a master of Zed Shots, stands with an X-Ray
                      emitting Zed Camera that returns point cloud data of
                      objects buried at a range of 500 meters, but once the data
                      is collected, he is having issues distinguishing Tim's
                      friends from the rubble. Tim gives him a hint that all his
                      friends are over a certain threshold in size, but David is
                      unable to make any progress. So, he turns to you for help.
                      Your job is to first count how many of Tim's friends are
                      picked up by the Zed Camera and classify them based on the
                      hints Dino Tim has given you. Do it quick or else Tim is
                      gonna eat David for good.
                    </p>
                    <br />
                    <div className="center-image">
                      <div
                        className="hide-for-small-only"
                        style={{
                          width: "40%",
                          height: "50%",
                          display: "inline-block",
                        }}
                      >
                        <center>
                          <img className="gif1" src={AI_gif1} alt="img" />
                          <br />
                          <br />
                          <figcaption>
                            <i>
                              The image represents 3D Point Cloud Visualization
                              , where blue corresponds to the Bedrock, green
                              represents the Fossils and Red denotes Rock and
                              Noise{" "}
                            </i>
                          </figcaption>
                          <br />
                          <br />
                        </center>
                      </div>
                    </div>
                    <br />

                    <h2 className="">
                      <b>A Fishy Situation</b>
                    </h2>

                    <p>
                      Gaurav and Subham are always engaged in chess battles,
                      trying to one up the other. They barely ever spend time
                      doing productive work, but Dino Tim is having none of
                      this, so he summons his friend stockfish to teach them a
                      lesson. Stockfish using its superior mathematical
                      abilities alters space time and takes Gaurav and Shubham
                      to a parallel Universe where they themselves are pieces on
                      a life-sized chess board, with Gaurav being a Queen and
                      Subham being a knight. Now Stockfish can at any time cover
                      3 boxes in a straight line, your goal is to help seize the
                      treasure on the board in the least number of moves while
                      making sure neither Gaurav nor Subham are captured by the
                      demonic Fish. To add to that the fish can also summon
                      pawns on the chess board.
                    </p>
                    <br />

                    <br />
                    <br />
                    <h2 className="">
                      <b>Shapes of You</b>
                    </h2>
                    <p>
                      Aryan, Anirudh and Anaadhi are always fighting over drive
                      control for our awesome bot NOVA. After NOVA's stunning
                      performance at IGVC 2024, the trio decide to set their
                      differences aside and set out for a long ride on NOVA. But
                      they don't want fellow AI member Aditi to feel left out so
                      they decide to add extra seating on NOVA. Unfortunately,
                      the awesome Mechanical team of MANAS hasn't arrived yet
                      and it's turning out to be difficult to make the necessary
                      joints. So, the AI members turn to you as their savior, to
                      recognize the shapes of the components that are present
                      and then adjust/shift it by the required amount so that
                      they fit in the correct place.
                    </p>
                    <div></div>
                    <br />
                    <br />
                    <div className="center-image">
                      <div
                        className="hide-for-small-only"
                        style={{ width: "50%" }}
                      >
                        <center>
                          <img className="embedded2-img" src={AI_3} alt="img" />
                          <br />
                          <br />
                          <figcaption>
                            <i>
                              Component Shape Recognition: Example
                              Representation{" "}
                            </i>
                          </figcaption>
                          <br />
                        </center>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div>
                      <h2 className="">
                        <b>MANAS Grand Prix</b>
                      </h2>

                      <p>
                        Vaishnavi Verstappen, one of the greatest F1 drivers of
                        all time, is determined to win this season of MANAS
                        Grand Prix. However, Aditi is proving to be a tough
                        competitor. They need your help to program the car to
                        navigate the circuit successfully. Your task is to write
                        a program that guides Vaishnavi's car to reach the
                        endpoint marked as an e, maintaining a safe distance
                        from obstacles and ensuring smooth, non-jerky turns.
                      </p>

                      <br />
                      <br />
                      <div className="center-image">
                        <div
                          className="hide-for-small-only"
                          style={{ width: "80%", height: "80%" }}
                        >
                          <center>
                            <img
                              className="embedded2-img"
                              src={AI_4}
                              alt="img"
                            />
                            <br />
                            <br />
                            <figcaption>
                              <i> Race Circuit Navigation: Example</i>
                            </figcaption>
                            <br />
                          </center>
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />
                    </div>
                    <div>
                      <h2 className="">
                        <b>Space Invaders</b>
                      </h2>

                      <p>
                        In the year 2124, Earth faces an unprecedented crisis as
                        an alien invasion threatens the planet. The aliens have
                        deployed various shapes of advanced drones to infiltrate
                        and conquer. Anirudh, the head of Area 51, has developed
                        a system to monitor these drones using satellite images.
                        However, the images are often distorted during
                        transmission, making it challenging to track the drones
                        accurately. Anirudh needs your help to trace the paths
                        of these alien drones across multiple frames and destroy
                        the alien species, saving Earth.
                      </p>
                    </div>
                    <div></div>
                    <br />
                    <br />
                    <div className="center-image">
                      <div
                        className="hide-for-small-only"
                        style={{ width: "70%", height: "50%" }}
                      >
                        <center>
                          <img
                            className="embedded2-img"
                            src={AI_gif5}
                            alt="img"
                          />
                          {/*<figcaption align = "center"><i> Example Representation</i></figcaption>
                                            <br /> */}
                          <br />
                          <br />
                          <figcaption>
                            <i> Space Invaders Visualization </i>
                          </figcaption>
                          <br />
                        </center>
                      </div>
                    </div>
                    <br />
                    <br />
                    <hr />
                    <br />
                    <h3>
                      <strong>Submission </strong>
                    </h3>
                    <p>
                      {" "}
                      The solutions will be accepted only through the
                      <a
                        href="https://www.hackerrank.com/project-manas-ai-recruitment-challenge-2024-2025"
                        rel="noreferrer"
                        target="_blank"
                      >
                        {" "}
                        HackerRank
                      </a>{" "}
                      website.{" "}
                    </p>
                    <center>
                      <span>
                        {" "}
                        For any queries related to recruitments, feel free to
                        contact (Preferably via Whatsapp):{" "}
                      </span>
                    </center>
                    <div className="contact">
                      <br />
                      <p>
                        <b> Om </b>
                        <br /> +91 8789603310
                        <br />
                      </p>
                      <p>
                        <b> Tanmay </b>
                        <br /> +91 9818075649
                        <br />
                      </p>
                    </div>
                  </object>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "white" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>
              <h3>Sensing and Automation</h3>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div
                id="sna-challenge"
                style={{ backgroundColor: "white", color: "black" }}
              >
                <div className="challenge-content">
                  <h1
                    className="black text-center"
                    style={{ color: "#f49221" }}
                  >
                    Sensing And Automation
                  </h1>
                  <br />
                  <p>
                    <b>
                      Only solve one of the following Subsystem's (Hardware,
                      Automation, Embedded Systems) challenges:
                    </b>
                  </p>
                  <h2 className="">
                    <strong>Hardware Recruitment Challenge</strong>
                  </h2>
                  <br />
                  <h3>
                    <u>
                      {" "}
                      <b>Just Sudeep </b>
                    </u>
                    <br />
                  </h3>
                  <p>
                  Karthikeya has a nephew who wants a small motorbike as his birthday present, but Karthikeya recently started his job and is too broke to afford a premade one. However, Karthikeya is an electronics student who wants to make a motorbike from scratch, which will be cheaper.
                    <br />
                    <br />
                    <b>
                    Help Karthikeya make a motor driver circuit for the motor that fits the given operation conditions and uses the limited parts given.{" "}
                    </b>
                    <br />
                    <br /><b>Parts available for the construction</b>
                    <ul style={{ marginLeft: '20px' }}>
                      <li> 1. Any timing device. </li>
                      <li> 2. All basic components( Opamps, resistor, capacitor, comparator, logic gates, etc. )</li>
                      <li> 3. A simple DC motor.</li>
                      <li> 4. Basic switches. </li>
                    </ul>
                    <br /> <b>The motor should rotate in three different modes:- </b>

                    <ul style={{ marginLeft: '20px'}}>
                      <li> 1. Mode 1 : The motor should rotate clockwise only.</li>
                      <li> 2. Mode 2 : The motor should rotate anticlockwise only. </li>
                      <li>
                        3. Mode 3 : The motor should rotate clockwise for time T' and
                        anticlockwise for time T".{" "}
                      </li>
                      </ul>
                      
                      <br /><b>Parameters of circuit. </b>
                      <br />
                      
                      <p>
                      <ul style={{ marginLeft: '20px'}}>
                        <li>1. Switching the motor from the clockwise mode of rotation(Mode_1) to the anticlockwise mode of rotation(Mode_2) or to Mode_3 can only be done through the switch(es) present in the circuit.
                        <br />
                        </li>
                       
                        <li>
                        2. The periods T' and T" should be controlled by two potentiometers.

                        <br /><b>Example:</b> In Condition_3, the motor rotates clockwise (T') for 7 sec and anti-clockwise (T") for 5 sec but after changing the values of potentiometers both T' and T" are different now.
                        </li>
                      </ul>
              
                      </p>
                    
                  </p>

                  <br />

                  <p>
                    {" "}
                    <b>Submission Rationale: </b>
                    <br />
                    All circuits mentioned above must be simulated. You can use
                    any circuit simulation software or use the one provided
                    below:
                    <br />
                    <a
                      href="https://www.falstad.com/circuit/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {" "}
                      https://www.falstad.com/circuit/{" "}
                    </a>
                    <br />
                    Send the simulation files along with a document explaining
                    the circuit and justification for the components used.
                    (Mention the simulation software that you have used in the
                    document)
                    <br />
                    <br />
                  </p>
                  <br />

                  <h2 className="">
                    <strong>Automation Recruitment Challenge</strong>
                  </h2>

                  <h3>
                    <u>
                      <b>Chief Warden </b>
                    </u>{" "}
                  </h3>
                  <p>
                    Sachin has just been hired as the lead engineer at a cutting-edge automation company. His first task is to develop an autonomous bot to collect signatures from all tenants in a large apartment building. Each tenant is only available during specific times of the day, and Sachin needs to ensure that the bot makes as few trips as possible to collect all signatures.
                    <br />
                    <br />
                    The building has ten floors, with multiple tenants on each floor. The tenants have provided availability times, and Sachin must program the bot to optimize its visits to minimize the number of trips.
                    <br />
                    <br />
                    As Sachin delves deeper into the project, he realizes that this task involves understanding the tenants' availability and ensuring the bot's sensors and automation systems are working flawlessly. He needs your help to solve this challenge efficiently and effectively.{" "}
                  </p>
                  <ul>
                    <li>
                      <b>1. Optimize Visits : </b> Given the tenants' availability, find the minimum number of trips the bot needs to make to collect all signatures. Each trip is only considered complete when the bot has collected signatures from all available tenants in that specific period. Write a Pxython /C-type implementation of your algorithm{" "}
                    </li>
                    <li>
                      <b>2. Sensor Suite: </b> Sachin must ensure that the bot can detect and avoid obstacles in the building. Which type of sensors would be best suited for this task? Explain your choice.
                      {" "}
                    </li>
                  </ul>

                  <p>
                    <b>Example</b>
                    <ul style={{ marginLeft: '20px' }}>
                      <li>Tenant A: 8:00 AM - 10:00 AM </li>
                      <li>Tenant B: 9:00 AM - 11:00 AM </li>
                      <li>Tenant C: 10:00 AM - 12:00 PM </li>
                      <li>Tenant D: 11:00 AM - 1:00 PM </li>
                    </ul>
                    <p>
                    How many Minimum number of trips does the bot need to make to collect signatures from all tenants?
                    {" "}
                    </p>
                  </p>

                  <br />

                  <h3>
                    <u>
                      <b>Drone’s Descent into the Depths </b>
                    </u>{" "}
                  </h3>
                  <p>
                    Kp is an adventurous scientist on a mission to estimate the relative water level between two random points in a lake. The lake is divided into an (n by m) grid, and each cell has a marker at the centre. Kp has a high-tech drone equipped to handle this challenge, but he needs your help to design the algorithm and sensor suite to complete this mission efficiently.
                    <br />
                    <br />
                    Each marker emits information as light (Li-Fi), accessible within a 50 cm radius. The drone must visit each marker from 50 cm without overshooting into the water
                    <br />
                    <br />
                    Your task is to help Kp design a comprehensive plan to estimate the relative water level difference between two random points in the lake. The drone will need to visit specific markers to gather data through Li-Fi communication without overshooting into the water. Each marker provides four values: The letters N, S, E, and W indicate the water level difference between the adjacent cells to the north, south, east, and west. The boundaries of the lake grid have unique markers that provide precise water levels since they do not have neighbouring cells outside the grid.
                    <p>
                    <br />
                      Tasks:
                      <ol style={{ marginLeft: '20px'}}>
                        <li>
                          <b>Algorithm Design:</b> Create an algorithm that efficiently calculates the relative water level difference between two points on the lake grid. The algorithm should reduce the number of markers visited. Using the above logic, develop a C-type or Python code.
                        </li>
                        <li>
                          <b>Control System:</b>  Design a control system to ensure the drone maintains approximately 50 cm above the marker and avoids overshooting into the water; explain your approach.
                          {" "}
                        </li>
                        <li>
                          <b>Sensor Suite: </b> Determine the minimum number of sensors and electronics onboard required for the entire operation.
                        </li>
                      </ol>
                    </p>
                  </p>

                  <br />
                  {/* <p> <b>Submission Rationale: </b> 
                        <br />
                        All submissions <b>must be made as a mail to the mail ID</b> appended at the end. The submission has to include compulsorily:
                        <ol>
                        <li>A .txt file containing the list of sensors/MCU/Memory devices used.</li>
                        <li>A .txt file containing the pseudocode</li>
                        <li>The pseudocode should include functions, and the flow of logic has to be such that it can be executed in a programming language and not rough undefined logic.</li>
                        <li>These files should be zipped and mailed to <a href="mailto:projectmanas.sna@gmail.com">projectmanas.sna@gmail.com</a></li>
                        </ol>

                        </p> */}
                  <br />

                  <h2 className="">
                    <strong>Embedded Systems Recruitment Challenge</strong>
                  </h2>

                  <h3>
                    <u>
                      <b> Kartikeya's Lazy Lounge</b>
                    </u>
                  </h3>

                  <p>
                    Kartikeya, an expert in Hardware electronics, is a brilliant (and lazy) individual who wants to develop an advanced home automation system to streamline the management of his living environment. The system integrates multiple subsystems designed to operate autonomously and keep its working space in the most optimal condition. The critical components of this solution include:
                    <br />
                    <br />
                    <ol style={{ marginLeft: '20px'}}>
                      <li>
                        <b>Automated Window Blinds: </b> These blinds are driven by a servo motor that adjusts based on ambient light intensity detected by a light-sensing Device. The system automatically opens the blinds during daylight and closes them at night.{" "}
                      </li>
                      <li>
                        <b>Dynamic Room Illumination: </b>The lighting system is such that the room's lights automatically turn off during the day and on at night. Users can manually override the automation; they can be simply turned on or off using a switch.
                        {" "}
                      </li>
                      <li>
                        <b>HVAC System Automation: </b> The heating, ventilation, and air conditioning (HVAC) system regulates the room temperature based on real-time readings from a digital temperature sensor and adjusts the Heating, Ventilation, and Air Conditioning accordingly.
                        {" "}
                      </li>
                      <li>
                        <b>Environmental Monitoring and Display: </b> The system includes an LCD module that provides real-time feedback on the room's temperature and the time of day (day or night), ensuring that all relevant environmental parameters are continuously monitored and displayed.
                        {" "}
                      </li>
                    </ol>
                    <p>
                      Help Karthikeya design such a system on TinkerCad. For the lights in the room, use a simple LED. For the HVAC system, use an RGB LED, which provides a visual representation of the system's response: glowing blue when the room is too hot (indicating cooling) and red when the room is too cold (indicating heating). The user can adjust the Temperature range for cooling or heating. An Arduino UNO R3 will control all the components used. (submission link must be TinkerCad) {" "}
                    </p>
                  </p>
                  <br />
                  <h3>
                    <u>
                      {" "}
                      <b>Kaustubh's Comm-Quest</b>
                    </u>
                    <br />
                  </h3>
                  <p>
                    Kaustubh is a Senior Product Developer at Sanam Instruments, and he has developed a 3-axis IMU S41722. This IMU is primarily made as a way for beginners to learn and implement the different ways of communication between sensors and microcontrollers. The S41722 IMU can communicate via three communication modes:

                    <ul style={{ marginLeft: '20px'}}>
                      <li>
                      1. I<sup>2</sup>C: Inter-Integrated Circuit developed by Philips Industries allows synchronous, half-duplex data transmission between devices.
                        {" "}
                      
                      </li>

                      <li>
                      2. S<sup>2</sup>I: The Single Serial Interface is a One Wire communication protocol developed by Sanam Instruments and is capable of asynchronous, half-duplex communication.
                      </li>
                      <li>
                      3. SPI: Serial Peripheral Interface, a 4-Wire Communication Protocol developed by Motorola, is capable of synchronous, full-duplex communication between multiple devices. {" "}
                      </li>
                    </ul>
                    Now, as a product developer, Kaustubh lacks the knowledge to write a header file to allow beginners to use the IMU module in their projects.
                    <br />
                    <br />
                    Help Kaustubh by writing an Arduino Header file that allows communication between the IMU module and an Arduino UNO R3 using the S<sup>2</sup>I communication protocol.
                    <br />
                    <br />
                    The Data Sheets of both 3-Axis S41722 IMU and the S<sup>2</sup>I communication protocol are given below:

                    <br />{" "}
                    <a href="https://bit-dk.web.app/Sanam_S2I">
                      S<sup>2</sup>I (https://bit-dk.web.app/Sanam_S2I)
                    </a>{" "}
                    <br />{" "}
                    <a href="https://bit-dk.web.app/Sanam_IMUS41722">
                      IMU S41722 ( https://bit-dk.web.app/Sanam_IMUS41722 ){" "}
                    </a>
                  </p>

                  <hr />
                  <br />
                  <h3>
                    <strong>Submission </strong>
                  </h3>
                  <p>
                    {" "}
                    The solutions need to be submitted along with your details
                    via email to :{" "}
                    <a href="mailto:projectmanas.sna@gmail.com">
                      {" "}
                      projectmanas.sna@gmail.com{" "}
                    </a>{" "}
                  </p>

                  <p>
                    <b>NOTE: </b> The submissions over mail need to be
                    accompanied with the following details:
                    <ol>
                      <li>Full Name</li>
                      <li>Registration Number</li>
                      <li>Branch</li>
                      <li>Contact Number</li>
                    </ol>
                  </p>
                  <p>
                    {" "}
                    For any queries, please feel free to contact (Preferably
                    Whatsapp):{" "}
                  </p>
                  <div className="contact">
                    <p>
                      <b>Kaustubh</b>
                      <br /> +91 9636379818
                      <br />
                    </p>
                    <p>
                      <b>Sachin</b>
                      <br /> +91 9946863307
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "white" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>
              <h3>Mechanical</h3>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div
                id="mech-challenge"
                style={{ backgroundColor: "white", color: "black" }}
              >
                <div className="challenge-content">
                  <h1
                    className="orange text-center"
                    style={{ color: "#f49221" }}
                  >
                    Mechanical
                  </h1>
                  <p>
                    The challenges are divided into two parts, Mechanics and
                    Design. The challenges are meant to test your innovative
                    skills as well as your basics in Mechanical and Mathematics.
                  </p>

                  <h2 className="">
                    <strong>Mechanics</strong>
                  </h2>
                  <h4>
                    <b>
                      <u>EASY</u>
                    </b>
                  </h4>
                  <p>
                    Mohit  had always been a tinkerer, with a knack for building things that were both practical and a bit whimsical. One day, while cooking breakfast, mohit  was struck with a brilliant (and slightly ridiculous) idea. Why should people have to walk all the way to the store to get groceries when a drone could deliver them right to their doorstep? And so, Freyjä, the delivery drone, was born. Mohit made a drone Freyjä to deliver groceries. 
                    It has a mass of 1.5kg is hovering at a
                    constant altitude. It encounters a horizontal wind gust with
                    a velocity of 5m/s. Determine:
                  </p>
                  <ul>
                    <li>
                      a. Will the drone tilt at an angle to counteract the wind?
                      If yes, calculate the angle θ required to counteract the
                      wind force. If not, give reasons.
                    </li>
                    <li>
                      b. The additional power required by the propellers to
                      maintain the altitude.
                    </li>
                    <li>
                      c. The maximum wind speed the drone can withstand if its
                      maximum power output is 50W.
                    </li>
                  </ul>
                  <p>
                    Wind speed = 5m/s
                    <br />
                    Air density = 1.225kg/m^3
                    <br />
                    Frontal area of the drone (A) = 0.05m^2
                  </p>
                  <p>
                    <strong>Assumptions:</strong>
                    <br />
                    - The wind force acts uniformly on the entire drone.
                    <br />- The drone’s propellers provide lift equally
                    distributed across all four propellers.
                  </p>
                  <h4>
                    <b>
                      <u>MEDIUM</u>
                    </b>
                  </h4>
                  <p>
                    A drone carrying a spherical payload of weight 400g is
                    released when the drone is on a non linear path travelling
                    at a speed of 64km/hr and at a height of 100m. When the
                    payload reaches terminal velocity in the vertical axis it
                    has an impact with a pendulum.
                  </p>
                  <ul>
                    <li>
                      a. Find the distance the payload travels before hitting
                      the pendulum.
                    </li>
                    <li>
                      b. Also calculate the height that the pendulum gains.
                    </li>
                  </ul>
                  <p>
                    Take the drag coefficient, coefficient of restitution,
                    radius 0.47, 0.45, 5cm. Assume no drag on the pendulum.
                  </p>

                  <h4>
                    <b>
                      <u>HARD</u>
                    </b>
                  </h4>
                  <p>
                    A Boeing 787 is approaching a runway. Derive a relation for
                    the final radius of the rear landing gear tyre after
                    touchdown. Assume the tyre to be incompressible. Also assume
                    all the required variables.
                  </p>

                  <h2 className="">
                    <strong>Design</strong>
                  </h2>
                  <h4>
                    <b>
                      <u>EASY</u>
                    </b>
                  </h4>
                  <p>
                    Nova, a four-wheeled ground vehicle, was designed to explore the most challenging terrains. But as Nova began its journey across rocky landscapes, it became clear that a simple suspension system was crucial to ensure stability and maintain all four wheels in contact with the ground.
                    Create a basic suspension system for a four wheeled ground
                    vehicle robot Nova that allows it to traverse uneven
                    terrain. The ground vehicle consists of two power wheels and
                    two castor wheels. The suspension should ensure that all
                    four wheels maintain contact with the ground while absorbing
                    shocks. Describe how your suspension design improves the
                    robot’s stability. Give a rough sketch of your design if
                    possible.
                  </p>
                  <h4>
                    <b>
                      <u>MEDIUM</u>
                    </b>
                  </h4>
                  <p>
                    Phunsukh Wangdu was known in his village as the go-to guy for any quirky mechanical problem. From fixing windmills to crafting intricate clocks, he had a knack for turning the most complex problems into simple, elegant solutions. But one day, Phunsukh was presented with a challenge that left even him scratching his head.
                    <br />
                    <br />The village's old textile mill was on the verge of shutting down. The main shaft that powered the looms needed to rotate in regularly alternating directions to weave the fabric, but the motor that drove the shaft could only rotate in one direction. The mill’s owner, Mr. Sharma, was in a panic. Without a solution, the looms would stop, and the mill would close.
                    <br />
                    <br />Phunsukh, intrigued by the challenge, agreed to take a look.

                    Phunsukh needs to develop a mechanism to rotate a shaft in
                    regularly alternating directions using only a single
                    unidirectional motor, a chain drive and a cylinder. Simple
                    alterations may be made to the components if necessary.
                    Provide a viable solution to the problem.
                  </p>
                  <h4>
                    <b>
                      <u>HARD</u>
                    </b>
                  </h4>
                  <p>
                    Bob had always been fascinated by speed. Ever since he was a little kid, he'd dreamt of running as fast as the wind. Now, fresh out of high school and with his passion for aerodynamics ignited, Bob had a wild idea: to test his running speed in a way no one else had before . 
                    Design a supersonic wind tunnel capable of simulating Mach 2
                    flow conditions. The tunnel must feature a
                    convergent-divergent nozzle with internal cooling systems to
                    manage heat, as well as provisions for integrating various
                    boundary layer control mechanisms. Outline the design
                    considerations for the nozzle geometry, including the shape
                    and dimensions of the convergent and divergent sections, to
                    ensure smooth and efficient flow transition from subsonic to
                    supersonic speeds. Additionally, describe how you would
                    incorporate sensors and control systems to monitor and
                    adjust the internal flow characteristics and maintain
                    accurate test conditions. Also account for compressibility
                    effects in nozzle design Show calculations if any.
                  </p>
                  <br />

                  <hr />
                  <br />
                  <h3>
                    <strong>Submission </strong>
                  </h3>
                  <p>
                    {" "}
                    Submit the design files, word docs, pictures or videos to
                    &nbsp;
                    <a href="mailto:projectmanas.mechanical@gmail.com ">
                      projectmanas.mechanical@gmail.com
                    </a>
                    .
                  </p>

                  <p>
                    <b>NOTE: </b> These questions can be either solved in a CAD
                    software (Fusion 360, Solidworks, etc.) or the idea can be
                    presented as a neat sketch explaining it in maximum details.
                    The submissions over mail need to be accompanied with the
                    following details:
                    <ol>
                      <li>Full Name</li>
                      <li>Registration Number</li>
                      <li>Branch</li>
                      <li>Contact Number</li>
                    </ol>
                  </p>

                  <p>
                    {" "}
                    For any queries, please feel free to contact (Preferably
                    Whatsapp):{" "}
                  </p>
                  <div className="contact">
                    <p>
                      <b>Farshad</b>
                      <br /> +91 9820539326
                      <br />
                    </p>
                    <p>
                      <b>Mahua Singh</b>
                      <br /> +91 7021474766
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "white" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography>
              <h3>Management</h3>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div
                id="management-challenge"
                style={{ backgroundColor: "white", color: "black" }}
              >
                <div className="challenge-content">
                  <h1
                    className="orange text-center"
                    style={{ color: "#f49221" }}
                  >
                    Management
                  </h1>
                  <p>
                    <span className="orange">
                      <b>Instructions:</b>
                    </span>
                    <br />
                    To be considered for the task phase, each candidate needs to
                    attempt the tasks specified under the categories{" "}
                    <b>Admin and Finance</b>, <b>Research</b> and{" "}
                    <b>Graphic Design</b>.
                    <br />
                    <b>At least one</b> of the tasks of the other categories{" "}
                    <b>(Video Editing and Web Development)</b> must be
                    attempted. These questions are an alternative method of
                    entry into the Project MANAS taskphase,<b> apart</b> from
                    the interviews.
                  </p>
                  <h2>
                    <u>Admin and Finance*</u>
                  </h2>
                  <p>
                    Project MANAS is participating in the SUAS 2024 competition
                    at St. Mary's County Regional Airport, California, MD, USA.
                    Create a plan for the logistics and accommodations for a
                    team of 15 people attending the competition. As part of the
                    management subsystem, outline the logistics, including
                    accommodation and hospitality, and create a comprehensive
                    budget plan, justifying all decisions for budget allocation.
                    Take into consideration the most efficient and
                    cost-effective strategies for renting and utilizing trucks
                    in the USA for transporting competition parts, considering
                    factors such as truck sizes, rental policies, insurance
                    requirements, and potential logistical challenges.
                    <br />
                    <br />
                    <b>NOTE</b>: You have been provided with all the details 6
                    months before the trip .
                  </p>
                  <h2>
                    <u>Research*</u>
                  </h2>
                  <p>
                    Project MANAS <b>requires 8 motors</b> fulfilling the
                    following specifications for the drone:
                    <br />
                    Voltage &lt; 50V
                    <br />
                    Power &lt; 3000 W
                    <br />
                    Propeller size &lt; 29 in
                    <br />
                    Thrust ~ 13kg
                    <br />
                    <br />
                    Your task is to research two companies (ideally one
                    international and one indigenous) that manufacture a motor
                    that meet the above mentioned specifications. The research
                    must include, but not be limited to the following pointers:
                    <br />
                    <br />
                    <ol>
                      <li> Company's product reliability</li>
                      <li>Company’s partners and customers</li>
                      <li>
                        Company’s Location (considering shipping and delivery)
                      </li>
                    </ol>
                    A company that meets the best fit among the above mentioned
                    criteria needs to be shown, along with a{" "}
                    <b>properly structured plan</b> on how the same will be
                    purchased.
                  </p>
                  <h2>
                    <u>Graphic Design*</u>
                  </h2>
                  <p>
                    You have to do <b>at least one of the two</b> tasks
                    mentioned below:
                    <br />
                    <br />
                    <ol>
                      <li>
                        Take the movie La La Land/Amélie/Deadpool and Wolverine
                        and design a visual summary that captures its essence in
                        the form of Instagram carousel posts. This could include
                        a mood board, a colour palette, typography choices, or
                        key imagery that represents the themes, emotions, or
                        style of the work. Write a caption for the same.
                      </li>
                      <li>
                        Design a visually engaging travel dump Instagram post
                        that not only captures the essence of a recent trip but
                        also showcases your photo editing and graphic design
                        skills. The post should consist of 4 to 8 slides, each
                        creatively edited and enhanced with graphic elements.
                        Write a caption for the same.
                        <br />
                        Take this as an example :
                        <br />
                        <a
                          href="
                        https://drive.google.com/drive/folders/123qlxUzCucpPGvY7cPuSlRD9UC_2tUWh?usp=sharingD
                            "
                        >
                          {" "}
                          https://drive.google.com/drive/folders/
                          <br />123qlxUzCucpPGvY7cPuSlRD9UC_2tUWh?usp=sharing
                        </a>
                        <br />
                        Don’t create a post that is  same as this one.
                        Instead, try to follow a similar aesthetic.
                        <br />
                        <br />
                        You are <b>recommended</b> to use Adobe Illustrator,
                        Adobe Photoshop and Figma to complete the task. If you
                        choose to use any other software, mention its name in
                        your submission.
                      </li>
                    </ol>
                    Installing Illustrator(For Windows users):
                    <br />
                    <a href="https://getintopc.com/softwares/graphic-design/adobe-illustrator-cc-2020-free-download-1062480/">
                      {" "}
                      https://getintopc.com/softwares/graphic-design/
                      <br />adobe-illustrator-cc-2020-free-download-1062480/
                    </a>
                    <br />
                    <br />
                    Installing Illustrator(For Mac ):
                    <br />
                    <a href="https://drive.google.com/drive/folders/1J2lLsf-_aqYtfPwKJ-fAWPFkTrG7z54W?usp=sharing">
                      {" "}
                      https://drive.google.com/drive/folders/
                      <br />1J2lLsf-_aqYtfPwKJ-fAWPFkTrG7z54W?usp=sharing
                    </a>
                    <br />
                    <br />
                    <b>NOTE:</b> Upload a drive link consisting of all your
                    previous work related to Graphic Design (if any).
                  </p>
                  <h2>
                    <u>Videography</u>
                  </h2>
                  <ul>
                    <li>
                    Create a seamless transition video showcasing the campus life from day to night. Record clips from various spots around the campus during the day, and then capture footage from the same spots at night. Merge the corresponding day and night clips to create smooth transitions between them. Enhance the video with appropriate effects to make the transitions fluid and visually appealing.
                    <br />
                            
                    <br /> Focus on creativity, smoothness of transitions, and overall video quality.

                    </li>
                  </ul>
                  You <b>SHOULD</b> use either of the following applications to
                  edit these videos.
                  <p>
                    
                    <br />
                    For Windows:
                    <br />
                    <br />
                    1. Premiere Pro
                    <br />
                    <a href="https://getintopc.com/softwares/video-editing/adobe-premiere-pro-2022-free-setup-download/">
                      https://getintopc.com/softwares/video-editing/
                      <br />adobe-premiere-pro-2022-free-setup-download/{" "}
                    </a>
                    <br />
                    2. DaVinci Resolve
                    <br />
                    <a href="https://www.blackmagicdesign.com/products/davinciresolve">
                      https://www.blackmagicdesign.com/products/
                      <br />davinciresolve{" "}
                    </a>
                    <br />
                    <br />
                    For Mac:
                    <br />
                    <br />
                    1. Premiere Pro
                    <br />
                    <a href="https://www.torrentmac.net/final-cut-pro-10-6-1/">
                      {" "}
                      https://www.torrentmac.net/final-cut-pro-10-6-1/{" "}
                    </a>
                    <br />
                    2. Final Cut Pro
                    <br />
                    <a href="https://www.torrentmac.net/adobe-premiere-pro-2021-v15-4-1/">
                      {" "}
                      https://www.torrentmac.net/adobe-premiere-pro-2021-v15-4-1/{" "}
                    </a>
                    <br />
                    2. DaVinci Resolve
                    <br />
                    <a href="https://www.blackmagicdesign.com/products/davinciresolve">
                      https://www.blackmagicdesign.com/products/
                      <br />davinciresolve{" "}
                    </a>
                    <br />
                    <br />
                    <b>NOTE:</b> Upload a drive link consisting of all your
                    previous work related to Video Editing (if any). If you use any other software, mention its name in your submission.
                  </p>
                  <h2>
                    <u>Web Development</u>
                  </h2>
                  <p>
                    <b>Maze Generator and Solver:</b>
                    <br />
                    Develop a program in JavaScript that generates a random maze
                    and allows the user to navigate through it using arrow keys.
                    <br />
                    <br /> <b>Bonus : </b> Add a feature where the user can draw
                    their own maze, and then challenge the program to solve it
                    automatically.
                    <br />
                    <b>NOTE:</b> Upload a drive link consisting of all your
                    previous work related to Web Development (if any).
                  </p>
                  <hr />
                  <br />
                  <h3>
                    <strong>Submission </strong>
                  </h3>
                  <p>
                    Email your answers and videos to{" "}
                    <a href="mailto:projectmanas.management@gmail.com ">
                      projectmanas.management@gmail.com
                    </a>
                    .
                  </p>
                  <p>
                    <b>NOTE: </b> The submissions over mail need to be
                    accompanied with the following details:
                    <ol>
                      <li>Full Name</li>
                      <li>Registration Number</li>
                      <li>Branch</li>
                      <li>Contact Number</li>
                    </ol>
                  </p>
                  <p>
                    {" "}
                    For any queries, please feel free to contact (Preferably
                    Whatsapp):{" "}
                  </p>
                  <div className="contact" align="center">
                    <p>
                      <b>Rijul </b>
                      <br /> +91 9896129688
                      <br />
                    </p>
                    <p>
                      <b>Ashwin </b>
                      <br /> +91 8292143739
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
